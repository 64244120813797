import { NavLink } from "react-router-dom";
import logoImperium from "assets/images/generalImages/logoImperium.png";
import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import closeMenu from "assets/images/generalImages/closeMenu.svg";
import user from "assets/icons/svgs/socialNetworks/user.svg";
import "assets/styles/navBar.css";

const NavbarMenu = ({ setOpenMenu, openMenu }) => {
  let body = document.querySelector("body");
  let root = document.getElementById("root");
  const popUpMenu = document.getElementById("popUpMenu");

  const onClickButtonClose = () => {
    body.style.overflow = "scroll";
    setOpenMenu(!openMenu);
    root.style.display = "block";
    popUpMenu.style.display = "none";
  };
  function hidePopUpNav() {
    setOpenMenu(!openMenu);
    popUpMenu.style.display = "none";
    root.style.display = "block";
    body.style.overflow = "scroll";
  }

  return (
    <>
      <figure className="h-full w-full flex items-center ">
        <img
          src={closeMenu}
          alt="icon close"
          onClick={onClickButtonClose}
          className="h-[50%]"
        />
      </figure>
      <figure className="h-full w-full">
        <a href="/" className="h-full w-full flex items-center justify-center">
          <img
            src={logoImperium}
            alt="logo Imperium"
            className="h-[28%] rotate-6"
          />
        </a>
      </figure>
      <img src={lineMobile} alt="line" className="w-full" />
      <div className="h-full w-full px-[22%] py-[9%] grid grid-rows-4 text-[12px] s-d-360:text-[14.5px] s-d-375:text-[15.5px] s-d-390:text-[16px] s-d-540:text-[12px] s-d-750:text-[17px]">
        <NavLink
          to="Functions"
          className="h-full w-full flex items-center"
          onClick={hidePopUpNav}
        >
          <p className="navbar-shadow ">¿Cómo funciona?</p>
        </NavLink>
        <NavLink
          to="Imperium"
          className="h-full w-full flex items-center"
          onClick={hidePopUpNav}
        >
          <p className="navbar-shadow">Sobre nosotros</p>
        </NavLink>
        <a  href="https://blog.imperium.cool" className="h-full w-full flex items-center">
          <p className="navbar-shadow">
            
          </p>
          Blog
        </a>
      </div>
      <img src={lineMobile} alt="line" className="w-full" />
      <div className="h-full w-full pl-[10%] pr-[22%] py-[9%] flex s-d-540:py-[5%]">
        <a
          href={`${process.env.REACT_APP_WEBSITE}/#/auth/login`}
          className="h-full w-full flex items-center flex-row justify-items-center"
        >
          <figure className="h-full w-[23%] flex items-center justify-center s-d-540:w-[18%]">
            <img
              src={user}
              alt="user"
              className="h-[80%] w-full flex items-center justify-center"
            />
          </figure>
          <p className="h-full w-[77%] flex items-center text-[12px] s-d-360:text-[14.5px] s-d-375:text-[15.5px] s-d-390:text-[16px] s-d-540:text-[12px] s-d-750:text-[17px]">
            Iniciar sesión
          </p>
        </a>
      </div>

      <img src={lineMobile} alt="line" className="w-full" />
      <div className="h-full w-full flex items-center justify-center ">
        <a href={`${process.env.REACT_APP_WEBSITE}`} className=" py-[3%] px-[10%] bg-cardBtn rounded-[20px] text-colorText f_text-breakPoints">
          Explorar
        </a>
      </div>
    </>
  );
};

export default NavbarMenu;

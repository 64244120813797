import Hero from "../components/Sections/Home/Hero";
import Oportunity from "../components/Sections/Home/Oportunity";
import Investment from "../components/Sections/Home/Investment";
import Adventure from "../components/Sections/Home/Adventure";
import Liquidity from "../components/Sections/Home/Liquidity";
import React from "react";

const Home = () => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      <Hero screenWidth={dimensions.width} />
      <Oportunity screenWidth={dimensions.width} />
      <Investment />
      <Liquidity />
      <Adventure screenWidth={dimensions.width} />
    </div>
  );
};
export default Home;

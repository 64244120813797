import NavbarMenu from "./NavbarMenu";
import hamburgerMenu from "assets/images/generalImages/hamburgerMenu.svg";
import logoImperium from "assets/images/generalImages/logoImperium.png";

import "assets/styles/navBar.css";
import { NavLink } from "react-router-dom";
import { PopUpMenu } from "./PopUpMenu";
import { useState } from "react";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  let body = document.querySelector("body");
  let root = document.getElementById("root");
  const popUpMenu = document.getElementById("popUpMenu");


  const handleMenu = () => {
    body.style.overflow = "hidden";
    root.style.display = "none";
    popUpMenu.style.display = "block";
    setOpenMenu(!openMenu);
  };

  return (
    <nav className="navBar w-full h-[5.8%] px-[3%] fixed z-20 flex justify-between items-center bg-[#0D0D0D] d-1000:px-[2.5%] d-1000:h-[12%] d-1000:bg-[#131314]">
      <img
        src={hamburgerMenu}
        onClick={handleMenu}
        alt="menu"
        className="h-[45%] d-1000:hidden"
      />
      <div className="h-[100%] w-[15.25%] hidden d-1000:block">
        <NavLink
          to=""
          className="h-full w-full flex items-center justify-content-center "
        >
          <img
            src={logoImperium}
            alt="imperium logo"
            className="h-[40%] rotate-6"
          />
        </NavLink>
      </div>

      {!!openMenu && (
        <PopUpMenu menu={handleMenu} openMenu={openMenu}>
          <NavbarMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
        </PopUpMenu>
      )}

      <div className="h-full w-[54.25%] hidden d-1000:block">
        <section className="w-full h-full grid grid-cols-4 font-bold text-colorText d-1000:text-f-textBtn-1000 d-1280:text-f-textBtn-1280">
          <NavLink
            to="Functions"
            className="h-full w-full flex items-center justify-center "
          >
            <p>¿Cómo funciona?</p>
          </NavLink>
          <NavLink
            to="Imperium"
            className="h-full w-full flex items-center justify-center"
          >
            <p>Sobre nosotros</p>
          </NavLink>
          <div className="h-full w-full flex items-center justify-center">
            <p>
              <a
                href="https://blog.imperium.cool"
                target="_blank"
                rel="noreferrer noopener"
              >
                Blog
              </a>
            </p>
          </div>
        </section>
      </div>

      <NavLink
        to="/"
        className="h-[100%] flex items-center flex-row-reverse ml-[52%] s-d-540:ml-[58%] d-1000:hidden "
      >
        <img
          src={logoImperium}
          alt="imperium logo"
          className="h-[80%] rotate-6"
        />
      </NavLink>
      <div className=" h-[100%] w-[30.5%] hidden d-1000:block">
        <section className="h-full w-full grid grid-cols-2 items-center justify-center justify-items-center">
       
            <a
              href={`${process.env.REACT_APP_WEBSITE}/#/auth/login`}
              rel="noreferrer noopener"
              className="h-1/2 w-[90%] font-bold flex items-center justify-center text-colorText d-1000:text-f-textBtn-1000 d-1280:text-f-textBtn-1280 border-[#F97575] border-[1px] rounded-[30px] hover:bg-[#F97575]"
            >
              Iniciar sesión
            </a>
            <a
            id="button-landing-explorar"
              href={`${process.env.REACT_APP_WEBSITE}`}
              rel="noreferrer noopener"
              className="h-1/2 w-[90%] font-bold flex items-center justify-center text-colorText d-1000:text-f-textBtn-1000 d-1280:text-f-textBtn-1280 border-[#F97575] border-[1px] rounded-[30px] hover:bg-[#F97575]"
            >
              Explorar
            </a>
          
        </section>
      </div>
    </nav>
  );
};

export default Navbar;

import next1 from "assets/icons/svgs/next1.svg";
import previous1 from "assets/icons/svgs/previous1.svg";

function SampleNextArrow({ onClick }) {
  return (
    <img
      src={next1}
      alt="next"
      onClick={onClick}
      className="h-[8%] absolute right-[1.5%] z-10 top-[32%] d-1000:h-[5.3%] d-1000:top-[46%] d-1000:right-[3.5%]"
    />
  );
}

function SamplePrevArrow({ onClick }) {
  return (
    <img
      src={previous1}
      alt="previous"
      onClick={onClick}
      className="h-[8%] absolute left-[1.5%] z-10 top-[32%] d-1000:h-[5.3%] d-1000:top-[46%]  d-1000:left-[3.5%]"
    />
  );
}
export { SampleNextArrow, SamplePrevArrow };

import "assets/styles/imperium.css";
const CardTeam = ({ isActive, key, name, img, jobTitle, description }) => {
  return (
    <section className="h-full w-full" key={key}>
      <div className="h-full w-full flex items-center relative px-[8%] mb-[115%]">
        <figure className="w-full h-full s-d-540:w-full flex items-center justify-center">
          <img
            src={img}
            alt={name}
            className="h-full w-full object-cover rounded-[10px] s-d-540:rounded-[100%] s-d-540:relative s-d-540:z-10 s-d-540:w-[85%]  "
          />
        </figure>
        <section className="h-full top-[90%] absolute w-[84%] px-[5%] py-[6%] bg-[#1C1B2D] flex flex-col rounded-[10px] s-d-540:pt-[24%] s-d-540:px-[6%] s-d-540:h-[160%] d-1000:h-[100%] shadow-card d-1000:pt-[10%] d-1000:top-[88%]  d-1000:pt-[16%] rounded-t-[20px]">
          <h1 className="h-[15%] text-[#FFFFFF] font-bold text-[11.2px] s-d-360:text-[14px] s-d-375:text-[15.2px] s-d-390:text-[15.7px] s-d-540:text-[12.3px] s-d-750:text-[19.3px] d-1000:text-[22px] d-1280:text-[25.5px] s-d-540:flex s-d-540:items-center s-d-540:justify-center s-d-540:font-semibold">
            {name}
          </h1>
          <h1 className="h-[20%] text-[#FFFFFF] leading-[100%] font-bold  text-[11.2px] s-d-360:text-[14px] s-d-375:text-[15.2px] s-d-390:text-[15.7px]  s-d-540:text-[12.3px] s-d-540:h-[31%] s-d-750:text-[19.3px] d-1000:text-[22px] d-1280:text-[25.5px] s-d-540:flex s-d-540:items-start s-d-540:justify-center s-d-540:text-center">
            {jobTitle}
          </h1>
          <p className="h-[100%] w-full textCard-description font-light flex items-start text-f-textBtn-280  s-d-540:font-normal s-d-540:text-[0.5313rem] s-d-540:h-[80%] s-d-750:text-[0.775rem] d-1000:text-[0.825rem] d-1280:text-[17.7px]">
            {description}
          </p>
        </section>
      </div>
    </section>
  );
};
export default CardTeam;

import OpportunityCards from "./cards/OpportunityCards";
import opportunities from "./data/Opportunities";
import { useState } from "react";
import blur from "assets/images/generalImages/blur.png";

const Opportunities = () => {
  const [selected, setSelected] = useState(2);
  const toogle = (i) => {
    setSelected(i);
  };

  return (
    <section className="bgstyle h-auto relative w-100% flex justify-items-center items-center flex-col d-1000:mb-[25%] d-1000:border-[#C389F1] d-1000:border-b-[4px]">
      <img
        src={blur}
        alt="blur"
        className="h-[30%] absolute top-[0%] left-[67%] s-d-540:h-[55%] s-d-540:left-[74%] s-d-540:top-[-15%] d-1000:left-[-23%] d-1000:h-[30%] d-1000:top-[52%] "
      />
      <img
        src={blur}
        alt="blur"
        className="hidden d-1000:block h-[50%] absolute top-[30%] left-[80%] d-1000:h-[25%] d-1000:left-[30%] d-1000:top-[90%]"
      />

      <h2 className="w-full py-[15%] px-[12%] flex items-center justify-center text-center text-title s-d-540:font-extrabold s-d-280:text-f-title-280 s-d-360:text-f-title-360 s-d-390:text-f-title-390 s-d-540:text-[30px] s-d-540:pt-[0%] s-d-540:py-[6%]  s-d-750:text-[45px] d-1000:text-[58px] d-1280:text-[72px]">
        Encuentra las mejores oportunidades para ti
      </h2>
      <div className="w-full">
        {opportunities.map((data, i) => (
          <OpportunityCards
            textButton={data.textButton}
            title={data.title}
            description={data.description}
            nft={data.nft}
            toogle={toogle}
            key={data.title}
            i={i}
            selected={selected}
            link={data.link}
          />
        ))}
      </div>
    </section>
  );
};
export default Opportunities;

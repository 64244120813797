import React from "react";
import buttonScroll from "assets/icons/svgs/buttonScroll.svg";
import { NftCarouselAbout } from "./components/NftsCarousel";
import blur from "assets/images/generalImages/blur.png";
import "assets/styles/imperium.css";
import nft11 from "assets/images/generalImages/nfts/nft11.png";
import { Link } from "react-scroll";
import CardAbout from "./Cards/CardAbout";

const ImperiumHero = () => {
  document.title = "Imperium — Imperium";
  return (
    <section className="w-[100vw] bg-[#0D0D0D] relative  flex flex-col items-center justify-center p-[6%] pt-[15%] s-d-540:flex-row s-d-540:h-auto s-d-540:px-[8%] s-d-540:pt-[10%]">
      <img
        src={blur}
        alt="blur"
        className="top-[76%] left-[36%] absolute s-d-540:top-[-20%] s-d-540:left-[21%] s-d-540:h-[100%]"
      />
      <img
        src={blur}
        alt="blur"
        className="hidden s-d-540:block absolute s-d-540:top-[36%] s-d-540:left-[70%] s-d-540:h-[90%]"
      />
      <div className="w-[88vw] flex flex-col s-d-540:h-auto s-d-540:flex-row s-d-540:items-center s-d-540:z-10">
        <div className="h-auto w-[100%] mb-[5%] s-d-540:hidden">
          <NftCarouselAbout />
        </div>
        <CardAbout />
        <section className="grid z-10 gap-y-[3%] pt-[5%] items-center justify-center s-d-540:hidden">
          <Link to="about" spy={true} smooth={true} offset={-35} duration={500}>
            <p className="text-colorText font-normal f_scroll-breakPoints">
              Más información
            </p>
            <figure className="h-auto flex items-center justify-center">
              <img src={buttonScroll} alt="icon scroll" className="pt-[8%]" />
            </figure>
          </Link>
        </section>
        <figure className=" w-full h-full hidden s-d-540:block s-d-540:pl-[3%]">
          <img src={nft11} alt="" className="  object-cover  w-[30.25rem]" />
        </figure>
      </div>
    </section>
  );
};

export default ImperiumHero;

import ImperiumHero from "../components/Sections/Imperium/ImperiumHero";
import About from "../components/Sections/Imperium/About";
import Team from "../components/Sections/Imperium/Team";
import React from "react";
const Imperium = () => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      <ImperiumHero screenWidth={dimensions.width} />
      <About screenWidth={dimensions.width} />
      <Team />
    </div>
  );
};

export default Imperium;

import React from "react";
import ReactDOM from "react-dom";
import "assets/styles/navBar.css";
const PopUpMenu = ({ children, openMenu }) => {
  return ReactDOM.createPortal(
    <section
      className={`${
        openMenu ? "menu-links" : ""
      } bg-[#1C1B2D]  font-Anek items-center grid p-[4%] pb-[30%] s-d-540:pb-[10%] h-[100vh]`}
    >
      {children}
    </section>,
    document.getElementById("popUpMenu")
  );
};
export { PopUpMenu };

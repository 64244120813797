import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";

const Layout = () => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div className="overflow-hidden bg-[#0D0D0D]">
      <Navbar screenWidth={dimensions.width} />
      <Outlet />
      <Footer screenWidth={dimensions.width} />
    </div>
  );
};

export default Layout;

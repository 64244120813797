import nft4 from "assets/images/generalImages/nfts/nft4.png";
import { OportunityCarrousel } from "./carousels/OportunityCarrousel";
import CardOportunity from "./carousels/cards/oportunity/CardOportunity";
import "assets/styles/home.css";
import mask from "assets/images/home/mask.svg";

const Oportunity = ({ screenWidth }) => {
  return (
    <section
      id="oportunityHome"
      className=" grid justify-center items-center p-[6%] pb-[20%] gap-y-[3%] s-d-540:grid-cols-2 s-d-540:py-[6%] s-d-540:px-[7%] s-d-540:gap-x-[3%] s-d-540:relative s-d-540:bg-[#0D0D0D] s-d-540:z-10 "
    >
      {screenWidth >= 540 ? (
        <div className="h-auto w-full relative">
          <img
            src={mask}
            alt=""
            className="h-auto absolute top-[-4%] z-10 w-full"
          />
          <OportunityCarrousel />
          <img
            src={mask}
            alt=""
            className="h-auto absolute bottom-[-4%] z-10 w-full"
          />
        </div>
      ) : (
        <figure className="w-full flex items-center justify-center ">
          <img
            src={nft4}
            alt="preview"
            className="w-full object-cover rounded-[10px] s-d-540:h-[80%]"
          />
        </figure>
      )}
      <CardOportunity />
    </section>
  );
};

export default Oportunity;

import buttonScroll from "assets/images/generalImages/scroll.svg";
import { Link } from "react-scroll";

const RedirectOportunity = () => {
  return (
    <section className="grid gap-y-[3%] pt-[5%] items-center justify-center">
      <Link
        to="oportunityHome"
        spy={true}
        smooth={true}
        offset={-35}
        duration={500}
      >
        <p className="text-colorText font-normal f_scroll-breakPoints">
          Más información
        </p>
        <figure className="h-auto flex items-center justify-center">
          <img src={buttonScroll} alt="icon scroll" className="pt-[8%]" />
        </figure>
      </Link>
    </section>
  );
};
export default RedirectOportunity;

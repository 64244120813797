import "assets/styles/home.css";
import logoImperium from "assets/images/generalImages/logoImperium.png";

import blurAdventure from "assets/images/generalImages/blurAdventure.svg";
import blur from "assets/images/generalImages/blur.png";

import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import lineDesktop from "assets/images/generalImages/lines/lineDesktop.svg";

const Adventure = ({ screenWidth }) => {
  return (
    <div className="relative">
      {screenWidth >= 540 ? (
        <img
          src={blurAdventure}
          alt="blur"
          className=" absolute right-[72%] top-[0%] w-[75%]"
        />
      ) : (
        <img
          src={blur}
          alt="blur"
          className="right-[-16%] top-[-44%] absolute"
        />
      )}

      <section className="px-[6%] relative s-d-540:flex s-d-540:flex-row py-[30%] s-d-540:pt-[5%] s-d-540:px-[7%] s-d-540:pb-[20%] d-1000:pb-[15%] d-1000:pt-[6%]">
        <article className="h-1/2 grid py-[15%] relative z-10 px-[6%] bg-[#1C1B2D] rounded-[10px] s-d-540:bg-transparent s-d-540:w-[50%] s-d-540:left-[25%] s-d-540:px-[0%] s-d-540:text-center">
          <h1 className=" text-colorText leading-[90%] h-full w-full pb-[6%] font-bold flex items-center s-d-280:text-f-title-280 s-d-360:text-f-title-360 s-d-390:text-f-title-390 s-d-540:text-[30px] s-d-540:font-extrabold s-d-750:text-[45px] d-1000:text-[58px] d-1280:text-[72px]">
            Únete a nuestra comunidad
          </h1>
          <figure className="w-full  py-[1%]  flex items-center">
            {screenWidth >= 540 ? (
              <img src={lineDesktop} alt="line" className="w-[100%]" />
            ) : (
              <img src={lineMobile} alt="line" className="w-[100%]" />
            )}
          </figure>
          <div className="h-full w-full ">
            <p className="text-[#FFFFFF] font-extralight leading-[128%] py-[6%]  h-[100%] w-[100%] flex items-center justify-start s-d-280:text-font-devices-tex-280 s-d-360:text-f-text-360 s-d-390:text-f-text-390 s-d-540:text-f-text-540 s-d-540:text-center s-d-750:text-f-text-750 d-1000:text-f-text-1000 d-1280:text-f-text-1280">
              Únete a la mejor comunidad en español para aprender sobre NFTs.
              Sin palabras complejas ni términos complicados.
            </p>
          </div>
          <div className="w-[100%] h-[100%] flex items-center justify-center">
            <a href="https://chat.whatsapp.com/HYYkGWVZkxSLyVTSQqxrWd" className=" w-[100%] font-bold text-[#FFFFFF] py-[2.5%] bg-[#F97575] rounded-[30px] flex justify-center items-center f_textBtn-breakPoints s-d-540:rounded-[8px]">
                Unirme a la comunidad
            </a>
          </div>
          <div className="w-full h-full hidden s-d-540:block">
            <figure className="w-full h-full flex items-center justify-center ">
              <img
                src={logoImperium}
                alt="imperium logo"
                className="h-[40%] rotate-6"
              />
            </figure>
          </div>
        </article>
      </section>

      {screenWidth >= 540 ? (
        <img
          src={blurAdventure}
          alt="blur"
          className=" absolute left-[72%] top-[0%] w-[75%]"
        />
      ) : (
        <img
          src={blur}
          alt="blur"
          className=" left-[-40%] bottom-[10%] absolute"
        />
      )}
    </div>
  );
};

export default Adventure;

import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import nft6 from "assets/images/generalImages/nfts/nft6.png";
import "assets/styles/home.css";
import { NavLink } from "react-router-dom";
const Liquidity = () => {
  return (
    <section className=" relative w-full grid justify-center items-center gap-y-[3%] gap p-[6%] z-10 s-d-540:grid-cols-2 s-d-540:pb-[6%] s-d-540:px-[7%] s-d-540:gap-x-[3%] d-1000:pt-[0%]">
      <figure className="w-full flex items-center justify-center s-d-540:h-full">
        <img
          src={nft6}
          alt="nft"
          className="w-full max-h-[43rem]  object-cover rounded-[10px] "
        />
      </figure>

      <article className="h-auto w-full card s-d-540:bg-transparent s-d-540:px-[0%]">
        <h1 className="flex font-bold items-center text-title f_title-breakPoints s-d-540:font-extrabold">
          Compra fracciones con cripto o dólares
        </h1>
        <figure className="w-full py-[1%] flex items-center">
          <img src={lineMobile} alt="" className="w-[100%] h-full" />
        </figure>
        <p className="h-full w-full flex items-center justify-start textCard-description f_text-breakPoints">
          En Imperium no tienes que tener criptomonedas para comprar NFTs,
          puedes hacerlo en segundos con tu cuenta de banco o tarjeta de
          débito/crédito.
        </p>
        <div className="w-full h-full flex items-center justify-center s-d-540:justify-start">
          <a href={`${process.env.REACT_APP_WEBSITE}`} className="w-full flex justify-center items-center btnCard f_textBtn-breakPoints s-d-540:w-[60%] d-1000:w-[50%]">
           Carga tu cuenta
          </a>
        </div>
      </article>
    </section>
  );
};
export default Liquidity;

import discord from "assets/icons/svgs/socialNetworks/discord.svg";
import instagram from "assets/icons/svgs/socialNetworks/instagram.svg";
import linkedin from "assets/icons/svgs/socialNetworks/linkedin.svg";
import twitter from "assets/icons/svgs/socialNetworks/twitter.svg";
import ghost from "assets/icons/svgs/socialNetworks/ghost.svg";
import "assets/styles/home.css";
import { NavLink } from "react-router-dom";
const FooterDesktop = () => {
  return (
    <footer className="bg-[#0D0D0D] h-full w-full hidden s-d-540:block relative">
      <div className="h-full w-full content-navDescktop absolute "></div>
      <div className="grid grid-cols-2 h-full w-full px-[6%] py-[8%] relative z-10">
        <section className="w-full h-full pr-[6%] grid border-r-[1px] border-[#FF7262] py-[8%]">
          <h1 className="font-normal text-[#F5F5F5] text-[21pxpx] s-d-750:text-[23px] d-1000:text-[25px] d-1280:text-[30px]">
            Imperium
          </h1>
          <p className="font-normal text-[#F97575] py-[3%] text-[10px] s-d-750:text-[15px] d-1000:text-[15px] d-1280:text-[18px]">
            Imperium es la plataforma que te permite participar en las
            oportunidades más cool del universo. A través de Imperium puedes
            comprar los mejores activos digitales (NFTs) que realmente te
            apasionan, de manera sencilla y sin importar tu presupuesto
          </p>
          <h1 className="font-normal  text-[21pxpx] text-[#F5F5F5] pb-[3%] s-d-750:text-[20px] d-1000:text-[20px] d-1280:text-[25px]">
            Social Media
          </h1>
          <div className="h-full w-full grid grid-cols-5 items-center justify-items-center d-1000:w-[65%]">
            <a
              href="https://discord.com/invite/ad5vHJB39f"
              className="h-full w-full"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={discord} alt="" className="h-[76%] py-[2%] pr-[6%]" />
            </a>
            <a
              href="https://twitter.com/imperium_cool"
              className="h-full w-full"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={twitter} alt="" className="h-[76%] py-[2%] pr-[6%]" />
            </a>
            <a
              href="https://www.instagram.com/imperium.cool/"
              className="h-full w-full"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={instagram}
                alt=""
                className="h-[89%] py-[2%] pr-[14%]"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/imperiumlatam/?originalSubdomain=pe"
              target="_blank"
              rel="noreferrer noopener"
              className="h-full w-full"
            >
              <img src={linkedin} alt="" className="h-[76%] py-[2%] pr-[6%]" />
            </a>
            <a
              href="https://imperium.ghost.io/"
              target="_blank"
              rel="noreferrer noopener"
              className="h-full w-full"
            >
              <img
                src={ghost}
                alt="https://imperium.ghost.io/"
                className="h-[76%] py-[2%] pr-[6%]"
              />
            </a>
          </div>
        </section>
        <section className="w-full h-full pl-[6%] grid z-10">
          <div className="w-full flex flex-row border-[#F97575] border-b-[1px] py-[3%]">
            <p className="font-normal text-[#F5FBF2] w-1/2 flex items-center justify-center pr-[3%] text-[11.2px] s-d-750:text-[15px] d-1000:text-[15px] d-1280:text-[18px]">
              ¿Qué esperas? Los mejores activos digitales de Web 3 te esperan.
            </p>
            <div className="w-full h-full flex items-center justify-center w-[45%]">
              <a target='_blank'
                href={`${process.env.REACT_APP_WEBSITE}`}
                className="w-full h-full flex items-center justify-center"
              >
                <button className="py-[3.5%] px-[3.5%] w-[90%] flex items-center justify-center font-bold text-[#FFFFFF] text-[8px] border-[#F97575] border-[1px] rounded-[30px] hover:bg-[#F97575] s-d-750:text-[13px] d-1000:text-[13px] d-1280:text-[16px]">
                  Explorar
                </button>
              </a>
            </div>
          </div>
          <div className="w-full flex items-start justify-center flex-col text-[11.2px] s-d-750:text-[15px] d-1000:text-[15px] d-1280:text-[18px]">
            <h1 className="font-semibold text-[#F5F5F5] py-[2%] ">Imperium</h1>
            <NavLink
              to="Functions"
              className="font-medium text-[#F97575] pb-[1%]"
            >
              ¿Cómo funciona?
            </NavLink>
            <NavLink
              to="Imperium"
              className="font-medium text-[#F97575] pb-[1%]"
            >
              Sobre nosotros
            </NavLink>
            <a
              href="https://imperium.ghost.io/"
              target="_blank"
              rel="noreferrer noopener"
              className="font-medium text-[#F97575] pb-[1%]"
            >
              Blog
            </a>
          </div>
        </section>
      </div>
    </footer>
  );
};
export default FooterDesktop;

const stepsToFollow = [
  {
    textButton: "Deposita",
    description:
      "Crea tu cuenta y recargala depositando cualquier cripto, o haciendo una transferencia bancaria de dólares o tu moneda favorita. Pronto: Tarjeta de crédito /débito",
    id: "deposita",
  },
  {
    textButton: "Explora",
    description:
      "Explora los mejores NFTs, descubre como generan valor y que es lo que el equipo detrás esta tratando de construir",
    id: "explora",
  },
  {
    textButton: "Invierte",
    description:
      "Invierte en tus NFTs favoritos. Por qué elegir sólo un NFT cuando con Imperium puedes comprar fracciones de todos tus NFTs favoritos.",
    id: "invierte",
  },
];
export default stepsToFollow;

import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import nft7 from "assets/images/generalImages/nfts/nft7.png";
import "assets/styles/functionGuide.css";
import { NavLink } from "react-router-dom";
const GuideToFractions = () => {
  return (
    <section className="w-full grid items-center justify-center justify-items-center p-[6%] gap-y-[3%] s-d-540:grid-cols-2 s-d-540:gap-x-[3%] s-d-540:px-[8%] s-d-540:pt-[0%] d-1000:pt-[0%]">
      <figure className="w-full flex items-center justify-center s-d-540:h-full">
        <img
          src={nft7}
          alt="nft"
          className=" w-full object-cover s-d-540:h-full s-d-540:rounded-[10px] "
        />
      </figure>

      <article className="w-full bg-[#1C1B2D]  p-[6%] grid rounded-[10px] s-d-540:p-[0%] z-10 s-d-540:bg-transparent">
        <h1 className=" w-full flex items-center text-title f_title-breakPoints  ">
          ¿Cómo se fracciona un NFT?
        </h1>
        <figure className="w-full flex items-center">
          <img src={lineMobile} alt="" className="w-[100%]" />
        </figure>
        <p className=" h-full w-full flex items-center justify-start d-1000:pb-[0%] textCard-description f_text-breakPoints">
          Dividimos los derechos económicos sobre cada NFT para que puedan ser
          adquiridos por cientos o miles de usuarios.
          <br />
          <br />
          Los derechos económicos sobre un NFT te hacen acreedor sobre cualquier
          ingreso que se genere con la venta o alquiler de ese NFT, proporcional
          a la cantidad de fracciones que tengas.
        </p>
        <div className="w-[100%] flex items-center justify-center s-d-540:hidden">
          <button className=" w-[100%] font-bold text-[#FFFFFF] bg-[#F97575] py-[2.5%] rounded-[30px] text-[16px] flex justify-center items-center s-d-280:text-f-textBtn-280 s-d-360:text-f-textBtn-360 s-d-390:text-f-textBtn-390 s-d-540:text-f-textBtn-540 s-d-750:text-f-textBtn-750 d-1000:text-f-textBtn-1000 d-1280:text-f-textBtn-1280">
            <a href="https://imperium.cool/explorer/blue-chips"target='_blank' >Ver la colección</a>
          </button>
        </div>
      </article>
    </section>
  );
};
export default GuideToFractions;

import next1 from "assets/icons/svgs/next1.svg";
import previous1 from "assets/icons/svgs/previous1.svg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <img
      src={next1}
      onClick={onClick}
      className="h-[13%] absolute right-[4%] z-10 top-[43.5%]"
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <img
      src={previous1}
      onClick={onClick}
      className="h-[13%] absolute left-[4%] z-10 top-[43.5%]"
    />
  );
}
export { SampleNextArrow, SamplePrevArrow };

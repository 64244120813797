import FractionsOfNFT from "../components/Sections/FunctionGuide/FractionsOfNFT";
import Opportunities from "../components/Sections/FunctionGuide/Opportunities";
import StepsToFollow from "../components/Sections/FunctionGuide/StepsToFollow";
import Functions from "../components/Sections/FunctionGuide/Functions";
import GuideToFractions from "../components/Sections/FunctionGuide/GuideToFractions ";
const FunctionGuide = () => {
  return (
    <div>
      <Functions />
      <StepsToFollow />
      <FractionsOfNFT />
      <GuideToFractions />
      <Opportunities />
    </div>
  );
};
export default FunctionGuide;

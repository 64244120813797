import nft1 from "assets/images/generalImages/nfts/nft1.jpeg";
import nft2 from "assets/images/generalImages/nfts/nft2.jpeg";
import nft3 from "assets/images/generalImages/nfts/nft3.jpg";

const carouselHomeData = [
  {
    img: nft1,
    title: "Invierte en los mejores activos del futuro",
    description:
      "Explora, descubre y colecciona fracciones de los mejores activos digitales (NFTs) de Web3 con solo US$10.",
    textButton: "Explorar",
    link: `${process.env.REACT_APP_WEBSITE}`,
  },
  {
    img: nft2,
    title: "¿Cómo funciona Imperium?",
    description:
      "Invierte colectivamente con usuarios de todo el mundo. Compra fracciones de NFTs de +US$ 10.000 con solo US$10.",
    textButton: "Ver más",
  },
  {
    img: nft3,
    title: "¿Preguntas sobre Web3?",
    description: "Mandanos un mensaje por WhatsApp y nosotros te ayudaremos.",
    textButton: "Ir a WhatsApp",
    link: "https://n9.cl/ui5p5",
  },
];
export { carouselHomeData };

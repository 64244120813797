import "assets/styles/home.css";
import GettingStartedGuideCarrouselMobile from "./carousels/GettingStartedGuideCarrousel-mobile";
import GettingStartedGuideCarrouselDescktop from "./carousels/GettingStartedGuideCarrousel-descktop";
import blur from "assets/images/generalImages/blur.png";

const Hero = ({ screenWidth }) => {
  return (
    <>
      {screenWidth >= 540 ? (
        <section className=" d-1000:h-[100vh] w-[100vw] mt-[10%] relative  d-1000:mt-[0%] ">
          <GettingStartedGuideCarrouselDescktop />
          <img
            src={blur}
            alt="blur"
            className="h-[100%] absolute top-[-22%] right-[23%]"
          />
          <img
            src={blur}
            alt="blur"
            className="h-[80%] absolute top-[46%] right-[-10%]"
          />
        </section>
      ) : (
        <section className="w-full bg-[#0D0D0D] flex flex-col p-[4%] pt-[16%] items-center justify-center pb-[15%] ">
          <GettingStartedGuideCarrouselMobile />
        </section>
      )}
    </>
  );
};

export default Hero;

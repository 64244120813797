import React from "react";
import Slider from "react-slick";
import "assets/styles/imperium.css";
import collageNFT1 from "assets/images/home/Mix01.png";
import collageNFT2 from "assets/images/home/Mix02.png";

const carrouselOportunityData = [collageNFT1, collageNFT2];

export const OportunityCarrousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      {carrouselOportunityData.map((collageNft, positioCollageNft) => (
        <img
          src={collageNft}
          alt={"collage NFT " + positioCollageNft}
          key={"collage NFT " + positioCollageNft}
          className="h-full object-cover w-full rounded-[10px] px-[2.2%]"
        />
      ))}
    </Slider>
  );
};

import { TeamCarousel } from "./components/TeamCarousel";
import "assets/styles/imperium.css";

const Team = () => {
  return (
    <section className=" w-full flex items-center flex-col">
      <h1 className="h-full w-full flex justify-center px-[8%] pt-[5%] items-center bg-colorCard s-d-540:pt-[0%] s-d-280:font-semibold s-d-540:bg-transparent text-center s-d-540:font-extrabold text-title f_title-breakPoints">
        Conoce a nuestro equipo
      </h1>
      <div className="w-[100%] flex items-center flex-col pt-[25%] s-d-540:pt-[7%]">
        <TeamCarousel />
      </div>
    </section>
  );
};

export default Team;

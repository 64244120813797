import nft5 from "assets/images/generalImages/nfts/nft5.jpeg";
import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import "assets/styles/home.css";
import { NavLink } from "react-router-dom";

const Investment = () => {
  return (
    <section
      id="investmentHome"
      className="grid justify-center items-center p-[6%] pb-[20%] block gap gap-y-[3%] s-d-540:grid-cols-2 s-d-540:pb-[6%]  s-d-540:px-[7%] s-d-540:gap-x-[3%] d-1000:pt-[0%]"
    >
      <figure className="w-full flex items-center justify-center s-d-540:h-full s-d-540:hidden">
        <img
          src={nft5}
          alt="nft"
          className="w-full object-cover rounded-[0.625rem]"
        />
      </figure>
      <article className="h-auto w-auto card s-d-540:bg-transparent s-d-540:px-[0%]">
        <h1 className="flex font-bold items-center text-title f_title-breakPoints s-d-540:font-extrabold">
          Invierte solo en los mejores activos digitales
        </h1>
        <figure className="w-full  py-[1.5%] flex items-center">
          <img src={lineMobile} alt="" className="w-full" />
        </figure>
        <p className="h-full w-full flex items-center justify-start textCard-description f_text-breakPoints">
          Nos cansamos de escuchar que los NFTs que compraste ya no valen nada.
          En Imperium hacemos todo el trabajo por ti: analizamos miles de NFTs y
          seleccionamos los mejores para que puedas invertir en ellos desde $10.
        </p>
        <div className="w-full h-full flex items-center justify-center s-d-540:justify-start">
          <a href={`${process.env.REACT_APP_WEBSITE}`} className="w-full py-[2.5%] flex justify-center items-center btnCard f_textBtn-breakPoints s-d-540:w-[60%] d-1000:w-[50%]">
            Ver Colección
          </a>
        </div>
      </article>
      <figure className="w-full h-full hidden  s-d-540:block ">
        <div className="flex h-full w-full items-center justify-center s-d-540:h-full">
          <img
            src={nft5}
            alt="nft"
            className="w-full  max-h-[43rem] object-top object-cover rounded-[10px]"
          />
        </div>
      </figure>
    </section>
  );
};

export default Investment;

import nft8 from "assets/images/generalImages/nfts/nft8.png";
import nft9 from "assets/images/generalImages/nfts/nft9.png";
import nft10 from "assets/images/generalImages/nfts/nft10.png";

const opportunities = [
  {
    textButton: "RTFKT",
    title: "RTFKT",
    description:
      "En el 2021, Nike compró RTFKT, la marca de lujo más conocida del Metaverso. Desde ese momento, no han dejado de construir  cosas increíbles.  A través de RTFKT, Nike busca integrar dos realidades, lo físico y lo virtual, creando una experiencia sin precedentes para sus consumidores.",
    nft: nft8,
    link: "https://rtfkt.com",
  },
  {
    textButton: "Blockbar",
    title: "Blockbar",
    description:
      "Una plataforma donde las marcas más reconocidas de licor en el mundo venden botellas de edición limitada como NFTs. Cada NFT puede ser redimido por su dueño en cualquier momento por una botella física, como este Johnnie Walker de 48 años, spoiler alert: sólo existen 288 en el mundo.",
    nft: nft9,
    link: "https://blockbar.com",
  },
  {
    textButton: "BAYC",
    title: "Bored Ape Yatch Club",
    description:
      "Cada mono otorga  a su dueño derechos comerciales de una marca global con crecimiento exponencial, que puede utilizar para construir lo que quiera (Brand-as-a-Service). Los puedes amar u odiar, pero BAYC es un fenómeno social sin precedentes que llegó para quedarse.",
    nft: nft10,
    link: "https://boredapeyachtclub.com",
  },
];
export default opportunities;

import discord from "assets/icons/svgs/socialNetworks/discord.svg";
import instagram from "assets/icons/svgs/socialNetworks/instagram.svg";
import linkedin from "assets/icons/svgs/socialNetworks/linkedin.svg";
import twitter from "assets/icons/svgs/socialNetworks/twitter.svg";
import ghost from "assets/icons/svgs/socialNetworks/ghost.svg";
import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import logoImperium from "assets/images/generalImages/logoImperium.png";
import { NavLink } from "react-router-dom";
import "assets/styles/home.css";

const FooterMobile = () => {
  return (
    <footer className="w-[100%] px-[6%] pt-[6%] bg-[#1C1B2D] s-d-540:hidden ">
      <div className="grid justify-center items-center relative">
        <figure className=" w-full flex items-center justify-start">
          <img
            src={logoImperium}
            alt="logo Imperium"
            className="w-[50%] rotate-6"
          />
        </figure>
        <p className="text-colorText w-full text-[9.5px] py-[10%] leading-[180%] font-medium flex items-center f_textBtn-breakPoints">
          Imperium es la plataforma que te permite participar en las
          oportunidades más cool del universo. A través de Imperium puedes
          comprar los mejores activos digitales (NFTs) que realmente te
          apasionan, de manera sencilla y sin importar tu presupuesto.
        </p>
        <figure className="w-full flex items-center justify-center s-d-360:py-[1%]">
          <img className="w-[100%]" src={lineMobile} alt="line" />
        </figure>
        <div className="grid grid-rows-4 grid-col-none text-colorText py-[10%] font-medium text-[11.2px] s-d-360:text-[14px] s-d-375:text-[15.2px] s-d-390:text-[15.7px]">
          <NavLink to="Functions">¿Cómo funciona?</NavLink>
          <NavLink to="Imperium">Sobre nosotros</NavLink>
          <a
            href="https://imperium.ghost.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Blog
          </a>
        </div>
        <figure className="w-full flex items-center justify-center s-d-360:py-[1%]">
          <img className="w-[100%]" src={lineMobile} alt="line" />
        </figure>
        <div className="content-socialNetworks w-full h-full grid py-[10%]">
          <h2 className="text-colorText h-full w-full pb-[6%] font-bold f_title-breakPoints">
            Redes sociales
          </h2>
          <div className="h-full w-[80%] flex items-center flex-row">
            <a
              href="https://discord.com/invite/ad5vHJB39f"
              target="_blank"
              rel="noreferrer noopener"
              className="w-1/5 h-full"
            >
              <img src={discord} alt="" className="h-[62%] py-[2%] pr-[6%]" />
            </a>
            <a
              href="https://twitter.com/imperium_cool"
              target="_blank"
              rel="noreferrer noopener"
              className="w-1/5 h-full"
            >
              <img src={twitter} alt="" className="h-[62%] py-[2%] pr-[6%]" />
            </a>
            <a
              href="https://www.instagram.com/imperium.cool/"
              target="_blank"
              rel="noreferrer noopener"
              className="w-1/5 h-full"
            >
              <img
                src={instagram}
                alt=""
                className="h-[73%] py-[2%] pr-[14%]"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/imperiumlatam/?originalSubdomain=pe"
              target="_blank"
              rel="noreferrer noopener"
              className="w-1/5 h-full"
            >
              <img src={linkedin} alt="" className="h-[62%] py-[2%] pr-[6%]" />
            </a>
            <a href="https://imperium.ghost.io/" className="w-1/5 h-full">
              <img
                src={ghost}
                alt="https://imperium.ghost.io/"
                target="_blank"
                rel="noreferrer noopener"
                className="h-[62%] py-[2%] pr-[6%]"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterMobile;

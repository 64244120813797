import "assets/styles/home.css";
import logoImperium2 from "assets/images/generalImages/logoImperium2.png";
import logoImperium from "assets/images/generalImages/logoImperium.png";
import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import lineDesktop from "assets/images/generalImages/lines/lineDesktop.svg";
import blurAdventure from "assets/images/generalImages/blurAdventure.svg";

const About = ({ screenWidth }) => {
  return (
    <section
      id="about"
      className="w-full grid items-center justify-center p-[6%] gap-y-[3%] mb-[20%] py-[15%] s-d-540:py-[8%] s-d-540:flex s-d-540:px-[0%] s-d-540:mb-[0%] s-d-540:mt-[0%] s-d-540:relative d-1000:py-[0%]"
    >
      <img
        src={blurAdventure}
        alt="blur"
        className="hidden absolute top-[0%] left-[75%] h-[90%] s-d-540:block"
      />

      <figure className="h-full w-full flex items-center justify-center s-d-540:hidden ">
        <img
          src={logoImperium2}
          alt="logo Imperium"
          className="bg-cover w-full"
        />
      </figure>
      <article className="h-auto w-[100%] bg-[#1C1B2D] grid rounded-[10px] p-[6%] s-d-540:bg-transparent s-d-540:w-[61%] s-d-540:justify-items-center">
        <h1 className="flex items-center s-d-540:text-center text-title f_title-breakPoints ">
          ¿Cómo nació Imperium?
        </h1>
        {screenWidth >= 540 ? (
          <figure className="w-full  py-[1.5%] flex items-center justify-center">
            <img src={lineDesktop} alt="line" className="w-[65%]" />
          </figure>
        ) : (
          <figure className="w-full  py-[1.5%] flex items-center">
            <img src={lineMobile} alt="line" className="w-[100%]" />
          </figure>
        )}
        <p className="h-full w-full flex items-center justify-start s-d-540:text-left f_text-breakPoints textCard-description">
          En el 2021 Chris y Santi se dieron cuenta de que había mucho talento
          construyendo con nueva infraestructura digital: NFTs.
          <br />
          <br />
          Cuando trataron de participar, notaron no podía invertir en los NFTs
          que querían ya que el precio de entrada superaba por mucho su
          presupuesto.
          <br />
          <br />
          Fundaron Imperium para eliminar esa y muchas otras barreras de
          entrada, con la finalidad de que todos podamos participare invertir en
          los mejores proyectos del futuro.
        </p>
        <figure className="hidden h-full w-full w-full flex items-center s-d-540:block">
          <img
            src={logoImperium}
            alt="logo Imperium"
            className="m-auto h-1/2 pt-[5%] rotate-6"
          />
        </figure>
      </article>

      <img
        src={blurAdventure}
        alt="blur"
        className="hidden absolute top-[0%] right-[75%] h-[90%] s-d-540:block"
      />
    </section>
  );
};

export default About;

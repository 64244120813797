import experience from "assets/images/functionGuide/experience.jpeg";
import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import "assets/styles/functionGuide.css";

const FractionsOfNFT = () => {
  return (
    <section className=" w-full flex items-center justify-start flex-col relative mb-[150%] s-d-360:mb-[140%] s-d-390:mb-[150%] s-d-540:grid s-d-540:grid-cols-2 s-d-540:gap-x-[3%] s-d-540:py-[6%] s-d-540:px-[8%] s-d-540:mb-[0%] d-1000:grid d-1000:gap-x-[3%]">
      <figure className="h-auto s-d-540:hidden">
        <img
          src={experience}
          alt="experience"
          className=" h-full object-cover "
        />
      </figure>
      <article className=" h-auto w-full grid justify-center items-center bg-colorCard rounded-tl-[50px] rounded-tr-[50px] absolute top-[82%] px-[12%] py-[8%] s-d-540:sticky s-d-540:bg-transparent s-d-540:p-[0%] s-d-540:top-[0%]">
        <h2 className=" w-full flex items-center text-title f_title-breakPoints ">
          ¿Cómo funcionan las fracciones de NFTs?
        </h2>
        <figure className="w-full flex items-center">
          <img src={lineMobile} className="w-full" alt="line" />
        </figure>
        <p className=" h-full w-full flex items-center justify-start d-1000:pb-[0%] textCard-description f_text-breakPoints">
          Tienes $1.000 y quieres invertir en una nave de edición limitada en tu
          videojuego favorito:
          <br />
          <br />
          Sin Imperium, no podrías hacer nada con esos $1.000 ya que la nave
          cuesta $10.000. 😭
          <br />
          <br />
          Con Imperium, puedes comprar el 10% de la nave con los $1.000 que
          tienes disponible. 😍
          <br />
          <br />
          Imperium te permite invertir en lo que quieras, sin importar tu
          presupuesto. Nunca más te perderás otra oportunidad.
        </p>
      </article>
      <div className="h-full w-full hidden s-d-540:block">
        <figure className="h-full w-full flex items-center justify-items-center ">
          <img
            src={experience}
            alt="experience"
            className="w-full h-full object-cover s-d-540:rounded-[10px] d-1000:w-full d-1000:h-[70%]"
          />
        </figure>
      </div>
    </section>
  );
};
export default FractionsOfNFT;

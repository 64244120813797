import Slider from "react-slick";
import dataTeam from "../data/team";
import CardTeam from "../Cards/CardTeam";
import "assets/styles/imperium.css";
import { useState, useRef } from "react";
import { SamplePrevArrow } from "../arrows/CarouselArrows-about";
import gem from "assets/images/generalImages/gem.png";
import "assets/styles/imperium.css";

const TeamCarousel = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [membersTeam, setMembersTeam] = useState(dataTeam);

  const slider = useRef();

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    cssEase: "linear",
    revArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          centerPadding: "22%",
          dots: true,
          dotsClass: "setOfGems",

          afterChange: () => {
            setUpdateCount(updateCount - 1);
          },
          beforeChange: () => {
            setSlideIndex(slideIndex + 1);
          },

          appendDots: (dots) => (
            <section>
              <div className="w-full h-full flex items-center justify-center">
                <div className=" positionsInTheCompany  grid items-start justify-center relative">
                  {dots}
                </div>
              </div>
            </section>
          ),
          customPaging: (i) => (
            <div>
              <img src={gem} alt="gem" className={`h-[100%] w-[100%] z-100`} />
              <p className="h-[30%] w-[40%] top-[35%] left-[30%]  text-center flex items-center justify-center leading-[150%] absolute  text-colorText text-[7.5px] s-d-360:text-[9.3px] s-d-375:text-[10px] s-d-390:text-[10.5px]">
                {membersTeam === i ? "" : membersTeam[i].jobTitle}
              </p>
            </div>
          ),
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <section className="w-[100%] prueba h-full pb-[135%] s-d-540:pb-[15%] s-d-540:w-[92%] d-1000:w-[86%]">
        <Slider ref={slider} {...settings}>
          {membersTeam.map((dataMembers, positionDataMembers) => {
            return (
              <CardTeam
                isActive={dataMembers.isActive}
                key={positionDataMembers}
                name={dataMembers.name}
                img={dataMembers.img}
                jobTitle={dataMembers.jobTitle}
                description={dataMembers.description}
              />
            );
          })}
        </Slider>
      </section>
    </>
  );
};
export { TeamCarousel };

import stepsToFollow from "./data/StepsToFollow";
import StepsToFollowCards from "./cards/StepsToFollowCards";
import blur from "assets/images/generalImages/blur.png";
import { useState } from "react";

const StepsToFollow = () => {
  const [selected, setSelected] = useState(0);
  const toogle = (i) => {
    setSelected(i);
  };
  return (
    <section
      id="stepsToFollow"
      className="w-full flex items-center justify-items-center flex-col relative"
    >
      <img
        src={blur}
        alt="blur"
        className="h-[20%] w-full absolute top-[6%] left-[55%] s-d-540:w-auto s-d-540:h-1/3 s-d-540:left-[74%] s-d-540:top-[-22%] d-1000:h-[50%] d-1000:top-[-35%]"
      />
      <img
        src={blur}
        alt="blur"
        className="h-[20%] w-full absolute hidden top-[6%] left-[55%] s-d-540:block s-d-540:w-auto s-d-540:h-1/3 s-d-540:left-[-15%] d-1000:top-[33%] d-1000:h-[60%] d-1000:left-[-23%]"
      />

      <h2 className="w-full h-full flex items-center px-[6%] py-[15%] justify-center text-colorText  text-center font-bold s-d-540:px-[7%] s-d-540:font-extrabold s-d-280:text-f-title-280 s-d-360:text-f-title-360 s-d-390:text-f-title-390 s-d-540:text-[30px] s-d-540:pt-[0%] s-d-540:pb-[6%]  s-d-750:text-[45px] d-1000:text-[58px] d-1280:text-[72px]">
        Usa Imperium en 4 simples pasos
      </h2>

      <div className="w-full flex items-center flex-col justify-center">
        <section className=" w-full z-10">
          {stepsToFollow.map((data, i) => (
            <StepsToFollowCards
              textButton={data.textButton}
              description={data.description}
              dataid={data.id}
              toogle={toogle}
              key={data.textButton}
              i={i}
              selected={selected}
            />
          ))}
        </section>
        <section className=" w-full">
          <div
            className=" w-full bg-[url(assets/images/generalImages/folderMobile.svg)] bg-no-repeat bg-contain flex items-center pl-[17%] py-[6%] s-d-375:pl-[17%] s-d-540:py-[4%] s-d-540:bg-cover s-d-540:px-[13%] d-1000:bg-[url(assets/images/generalImages/folder-desktop-right.svg)] d-1000:flex d-1000:justify-end
                     d-1000:bg-contain d-1000:py-[2%] d-1000:px-[9.5%]"
          >
            <button className="h-auto w-auto py-[2%] px-[5%] font-bold text-colorText bg-cardBtn rounded-[5px] flex justify-center items-center d-1000:py-[1%] d-1000:px-[4%] f_textBtnFunctions-breakPoints">
              Colecciona
            </button>
          </div>
          <article className="px-[20%] w-full pb-[17%] px-[17%] pt-[6%] s-d-540:pb-[6%] border-[#C389F1] s-d-540:border-b-[4px] s-d-540:pl-[13%] s-d-540:pr-[36%]  s-d-540:pt-[0%] s-d-540:pb-[6%] s-d-750:border-b-[6px] d-1000:pt-[2%] d-1000:pb-[5%] d-1000:pl-[11%] d-1000:pr-[37%]">
            <p className="h-full textCard-description flex items-center s-d-280:leading-[160%] f_text-breakPoints s-d-540:py-[0%]  d-1000:leading-[120%]">
              Conviertete en un coleccionista de NFTs. Monitorea tu balance y
              tus inversiones, conversa con personas increíbles que invierten en
              lo mismo que tú y accede a experiencias exclusivas.
            </p>
          </article>
        </section>
      </div>
    </section>
  );
};
export default StepsToFollow;

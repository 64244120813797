import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";

const CardAbout = () => {
  return (
    <article className="w-full card z-10 s-d-540:bg-transparent s-d-540:p-[0%] s-d-540:pr-[3%]">
      <h1 className="flex items-center text-title s-d-540:font-extrabold f_title-breakPoints">
        Sobre nosotros: Imperium
      </h1>
      <figure className=" py-[1%] w-full  flex items-center justify-center">
        <img className="w-[100%] " src={lineMobile} alt="line" />
      </figure>
      <p className="textCard-description flex items-center justify-start f_text-breakPoints">
        Imperium no es otro marketplace de NFTs más. Somos LA plataforma que te
        permite invertir en los activos del futuro, sin miles de dólares,
        experiencias complejas y en segundos.
      </p>
      <div className="w-full h-full flex justify-center s-d-540:justify-start">
          <a
         
             href={`${process.env.REACT_APP_WEBSITE}/#/auth/register`}
            target="_blank"
            rel="noreferrer noopener"
            className="w-full btnCard flex justify-center items-center f_textBtn-breakPoints font-extrabold s-d-540:w-[60%] "
          >
            Quiero unirme
          </a>
      </div>
    </article>
  );
};
export default CardAbout;

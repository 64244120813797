import Layout from "./components/layout/Layout";

function App() {

  // console.log('environment', process.env.REACT_APP_ENVIRONMENT)
  
  return <Layout />;
}

export default App;

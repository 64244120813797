import "assets/styles/functionGuide.css";

const StepsToFollowCards = ({
  textButton,
  description,
  toogle,
  i,
  selected,
  dataid,
}) => {
  return (
    <>
      <div
        className={`w-full bg-[url(assets/images/generalImages/folderMobile.svg)] bg-no-repeat bg-contain flex items-center pl-[17%] py-[6%] z-10 s-d-375:pl-[17%] s-d-540:py-[4%] s-d-540:bg-cover s-d-540:px-[13%] ${
          i % 2 == 1
            ? "d-1000:bg-[url(assets/images/generalImages/folder-desktop-right.svg)] d-1000:flex d-1000:justify-end"
            : "d-1000:bg-[url(assets/images/generalImages/folder-desktop-left.svg)]"
        } d-1000:bg-contain d-1000:py-[2%] d-1000:px-[11%]`}
      >
        <button
          onClick={() => toogle(i)}
          className="h-auto w-auto py-[2%] px-[5%] font-bold text-colorText bg-cardBtn rounded-[5px] flex justify-center items-center d-1000:py-[1%] d-1000:px-[4%] f_textBtnFunctions-breakPoints"
        >
          {textButton}
        </button>
      </div>
      <article
        className={`${
          selected === i ? "content-stepsToFollowCard" : "stepsToFollowCard"
        }  px-[13%] w-full s-d-375:pl-[17%]`}
      >
        <p className="h-full textCard-description flex items-center s-d-280:leading-[160%] f_text-breakPoints s-d-540:py-[0%] d-1000:leading-[120%]">
          {description}
        </p>
      </article>
    </>
  );
};
export default StepsToFollowCards;

import "assets/styles/functionGuide.css";
const OpportunityCards = ({
  textButton,
  title,
  description,
  nft,
  toogle,
  i,
  selected,
  link,
}) => {
  return (
    <>
      <div
        className={`bg-[url(assets/images/generalImages/folderMobile.svg)] bg-no-repeat bg-contain flex items-center  pl-[17%] py-[6%] s-d-375:pl-[17%] s-d-540:py-[4%] s-d-540:px-[13%] s-d-540:bg-cover ${
          i % 2 === 1
            ? "d-1000:bg-[url(assets/images/generalImages/folder-desktop-right.svg)] d-1000:flex d-1000:justify-end"
            : "d-1000:bg-[url(assets/images/generalImages/folder-desktop-left.svg)]"
        } d-1000:bg-contain d-1000:py-[2%] d-1000:px-[11%]`}
      >
        <button
          onClick={() => toogle(i)}
          className="h-auto w-auto py-[2%] px-[5%] font-bold text-colorText bg-cardBtn rounded-[5px] flex justify-center items-center d-1000:py-[1%] d-1000:px-[4%] f_textBtnFunctions-breakPoints"
        >
          {textButton}
        </button>
      </div>
      <div
        className={`${
          selected === i ? "content-opportunityCard " : "opportunityCard"
        } ${
          i === 2 ? "d-1000:pb-[8%]" : ""
        } px-[10%] h-[70%] s-d-540:grid s-d-540:grid-cols-2 s-d-540:gap-[3%] s-d-540:h-auto s-d-540:items-center`}
      >
        <div className="grid s-d-540:py-[6%]">
          <h2 className=" text-center w-full flex items-center justify-center text-title s-d-280:pb-[2%] text-[16.5px] s-d-360:text-[20px] s-d-375:text-[21.1px] s-d-390:text-[22.7px] s-d-540:text-[15.2px] s-d-750:text-[23.9px] s-d-540:text-left s-d-540:justify-start d-1000:text-f-text-1000 d-1280:text-f-text-1280">
            {title}
          </h2>
          <p className="flex items-center font-normal pb-[6%] text-[11.3px] textCard-description s-d-360:text-[14px] s-d-375:text-[14.6px] s-d-390:text-[15.7px] s-d-540:text-[10.5px] s-d-750:text-[23.9px] d-1000:text-f-text-1000 d-1280:text-f-text-1280">
            {description}
          </p>
           <a target='_blank' className="font-semibold text-[0.875rem] w-28 text-center md:text-[1rem] rounded text-colorText p-2 my-8 bg-cardBtn" href={link}>Ir al sitio</a>
        </div>
        <figure className="flex items-start py-[8%] s-d-540:py-[0%] s-d-540:h-full s-d-540:items-center">
          <img
            src={nft}
            alt="nft"
            className=" s-d-540:h-[80%] s-d-540:object-cover s-d-540:rounded-[10px] s-d-540:ml-[5%]"
          />
        </figure>
       
      </div>
    </>
  );
};
export default OpportunityCards;

import React from "react";
import ReactDOM from "react-dom/client";
import "./input.css";
import App from "./App";

import { Route, Routes, HashRouter } from "react-router-dom";
import Home from "./views/Home";
import Imperium from "./views/Imperium";

import ScrollToTop from "./ScrollToTop";
import Oportunity from "./components/Sections/Home/Oportunity";
import FunctionGuide from "./views/FunctionGuide";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="Imperium" element={<Imperium />} />
          <Route path="Oportunity" element={<Oportunity />} />
          <Route path="Functions" element={<FunctionGuide />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import { NavLink } from "react-router-dom";
import RedirectOportunity from "../../arrows/RedirectOportunity";

const CardMobile = ({
  title,
  description,
  positionDataHome,
  textButton,
  link,
}) => {
  return (
    <div className="px-[2%] pt-[3%]" key={"card" + positionDataHome}>
      <article className="card bg-colorCard">
        <h1 className="flex items-center font-bold text-title f_title-breakPoints">
          {title}
        </h1>
        <figure className="h-full w-full flex items-center">
          <img className="w-full" src={lineMobile} alt="line" />
        </figure>
        <p className="h-full w-full flex items-center justify-start textCard-description f_text-breakPoints">
          {description}
        </p>
        <div className="w-full h-full flex items-center justify-center ">
          <a  href={link} className="w-full flex justify-center items-center btnCard f_textBtn-breakPoints">
            {positionDataHome === 1 ? (
              <NavLink
                to="Functions"
                className="w-full flex justify-center items-center btnCard f_textBtn-breakPoints "
              >
                {textButton}
              </NavLink>
            ) : (
              <a className="w-full flex justify-center z-50 items-center btnCard f_textBtn-breakPoints" >{textButton}</a>
            )} 
          </a>
        </div>
      </article>
      <RedirectOportunity />
    </div>
  );
};
export default CardMobile;

// Top members
import Santi from "assets/images/team/Santi.png";
import Christian from "assets/images/team/Christian.png";

// Members
import Martin from "assets/images/team/Martin.png";
import Jorge from "assets/images/team/Jorge.png";
import Brisset from "assets/images/team/Brisset.png";
import Candela from "assets/images/team/Candela.jpg";
import Alejandra from "assets/images/team/Alejandra.png";
import Sergio from "assets/images/team/Sergio.png";
import Sebastian from "assets/images/team/Sebastian.png";
import Joaquin from "assets/images/team/Joaquin.png";

import "assets/styles/imperium.css";

const dataTeam = [
  {
    img: Santi,
    name: "Santiago Tapia",
    jobTitle: "CEO",
    description:
      "Conocido como @elgfe, sueña con que todos los latinos tengan acceso a almacenes de valor y su objetivo es democratizar el acceso a activos alternativos, desde NFTs hasta relojes físicos, desde arte hasta vinos.",
    isActive: false,
  },
  {
    img: Christian,
    name: "Christian Pasquel",
    jobTitle: "CTO",
    description:
      "Con más de 10 años trabajando en R&D de tecnología, 7 de ellos en crypto/DLTs",
    isActive: true,
  },
  {
    img: Martin,
    name: "Martin Grisolle",
    jobTitle: "Product Analyst",
    description:
      "Aficionado de los NFTs y Web3. Experiencia previa en estrategia de productos digitales",
    isActive: false,
  },
  {
    img: Jorge,
    name: "Jorge Matute",
    jobTitle: "Front-End Developer",
    description:
      "Front-End Developer autodidacta, apasionado con el gaming y comprometido a desarrollar aplicaciones web amigables y escalables. ",
    isActive: false,
  },
  {
    img: Brisset,
    name: "Brisset Corcino",
    jobTitle: "Back-End Developer",
    description:
      "Con diversos proyectos para FrontEnd y BackEnd utilizando el pensamiento analítico ",
    isActive: false,
  },
  {
    img: Candela,
    name: "Candela Rabec",
    jobTitle: "Community Manager",
    description:
      "Busco en los más minuciosos detalles manteniendo siempre los valores que nos hacen humanos. En constante actualización",
    isActive: false,
  },
  {
    img: Sergio,
    name: "Sergio Zeballos ",
    jobTitle: "Researcher & Content Analyst",
    description:
      "Egresado de Negocios Internacionales que encontró su pasión en la industria web3.",
    isActive: false,
  },
  {
    img: Alejandra,
    name: "Alejandra Arévalo",
    jobTitle: "Front-End Developer",
    description:
      "Desarrolladora Frontend con experiencia en tecnologías web y soluciones digitales.",
    isActive: false,
  },
  {
    img: Sebastian,
    name: "Sebastian Chavez",
    jobTitle: "Growth Intern",
    description:
      "Me apasionan las ideas de alto impacto y busco formas creativas de lograr nuestros objetivos",
    isActive: false,
  },
  {
    img: Joaquin,
    name: "Joaquin ",
    jobTitle: "Project Manager",
    description:
      "Autodidacta, asertivo, creativo y orientado a resultados. Entusiasta de Web3 y NFTs",
    isActive: false,
  },
];
export default dataTeam;

import { useState } from "react";
import Slider from "react-slick";

import "assets/styles/imperium.css";

import CardMobile from "./cards/hero/CardMobile";
import { SampleNextArrow, SamplePrevArrow } from "./arrows/ArrowsMobile";
import { carouselHomeData } from "../data/carouselHomeData";

const GettingStartedGuideCarrouselMobile = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  const settings2 = {
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <div className="h-[87%] w-[100%] s-d-375:h-[90%]">
      <div className="h-[42%] w-[100%]">
        <Slider
          {...settings}
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
        >
          {carouselHomeData.map((data, datai) => (
            <div className="h-full w-full " key={"nft " + datai}>
              <img
                src={data.img}
                alt={"nft" + datai}
                className="object-cover h-[93%] w-full rounded-[10px] px-[2%] "
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="h-[58%] w-[100%] flex items-center justify-center  ">
        <Slider
          {...settings2}
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          slidesToShow={1}
          swipeToSlide={true}
          focusOnSelect={true}
        >
          {carouselHomeData.map((dataHome, positionDataHome) => (
            <CardMobile
              title={dataHome.title}
              description={dataHome.description}
              positionDataHome={positionDataHome}
              textButton={dataHome.textButton}
              link={dataHome.link}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default GettingStartedGuideCarrouselMobile;

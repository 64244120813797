import next1 from "assets/icons/svgs/next1.svg";
import previous1 from "assets/icons/svgs/previous1.svg";

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <img
      src={next1}
      alt="next"
      onClick={onClick}
      className="h-[13%] absolute right-[2%] z-10 top-[43.5%]"
    />
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <img
      src={previous1}
      alt="previous"
      onClick={onClick}
      className="h-[13%] absolute left-[2%] z-10 top-[43.5%]"
    />
  );
};
export { SampleNextArrow, SamplePrevArrow };

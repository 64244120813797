import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import { NavLink } from "react-router-dom";

const CardDesktopHero = ({
  title,
  description,
  textButton,
  link,
  positionDataHome,
}) => {
  return (
    <section className="w-full grid pl-[3%] ">
      <h1 className="text-title h-full w-full pb-[3%] flex items-center justify-start f_title-breakPoints s-d-540:font-extrabold">
        {title}
      </h1>
      <figure className="h-full w-full flex items-center d-1000:h-auto d-1000-w-auto">
        <img
          className="w-[100%] d-1000:h-auto  d-1000:w-auto"
          src={lineMobile}
          alt="line"
        />
      </figure>
      <p className="textCard-description font-light h-full w-full pt-[3%] flex items-center justify-start  f_text-breakPoints">
        {description}
      </p>
      <div className="pb-[6%] h-full w-full">
        <a href={link} className="font-bold btnCard flex justify-center items-center f_textBtn-breakPoints s-d-540:w-[60%] d-1000:w-[50%]">
          {positionDataHome === 1 ? (
            <NavLink
              to="Functions"
              className="h-full w-full flex items-center justify-center"
            >
              {textButton}
            </NavLink>
          ) : (
            <a>{textButton}</a>
          )}
        </a>
      </div>
    </section>
  );
};
export default CardDesktopHero;

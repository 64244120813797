import Slider from "react-slick";
import nft11 from "assets/images/generalImages/nfts/nft11.png";
import nft12 from "assets/images/generalImages/nfts/nft12.png";
import nft13 from "assets/images/generalImages/nfts/nft13.png";
import nft14 from "assets/images/generalImages/nfts/nft14.png";

import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../arrows/CarouselArrows-about";
import "assets/styles/imperium.css";

const SetOfNFT = [nft11, nft12, nft13, nft14];

const NftCarouselAbout = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  return (
    <Slider {...settings}>
      {SetOfNFT.map((img, positionImg) => (
        <img
          src={img}
          alt="NFT"
          key={"nft" + positionImg}
          className="h-full object-none w-full rounded-[10px]"
        />
      ))}
    </Slider>
  );
};

export { NftCarouselAbout };

import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import { NavLink } from "react-router-dom";

const CardOportunity = () => {
  return (
    <article className="w-full card s-d-540:bg-transparent d-1000:px-[0%]">
      <h1 className="flex items-center s-d-540:font-extrabold text-title  f_title-breakPoints">
        Los mejores activos digitales  a tu alcance
      </h1>
      <figure className="h-full w-full flex items-center">
        <img src={lineMobile} alt="line" className="w-[100%]" />
      </figure>
      <p className="h-full w-full flex items-center justify-start textCard-description f_text-breakPoints">
        ¿Quieres comprar un NFT pero no quieres empezar con $15.000? ¡Con
        Imperium puedes hacerlo! Empieza a invertir en los mejores NFTs con solo
        $10.
      </p>
      <div className="w-full h-full flex items-center justify-center s-d-540:justify-start d-1000:justify-start">
       
          <NavLink
           to="Functions"
            rel="noreferrer noopener"
            className=" w-full flex justify-center z-50 items-center btnCard f_textBtn-breakPoints"
          >
            ¿Cómo lo hacemos?
          </NavLink>
       
      </div>
    </article>
  );
};
export default CardOportunity;

import nft1 from "assets/images/generalImages/nfts/nft1.png";
import lineMobile from "assets/images/generalImages/lines/lineMobile.svg";
import scroll from "assets/images/generalImages/scroll.svg";
import blur from "assets/images/generalImages/blur.png";
import { Link } from "react-scroll";

const Functions = () => {
  return (
    <section className="h-full w-full bg-[#0D0D0D] p-[6%] gap-y-[6%] mb-[10%] grid items-center flex-col justify-center pt-[16%] s-d-540:relative s-d-540:h-auto s-d-540:mb-[0%] s-d-540:pt-[10%]">
      <img
        src={blur}
        alt="blur"
        className="hidden absolute top-[-45%] left-[20%] w-[60%] s-d-540:block d-1000:top-[-40%]"
      />

      <div className="w-[100%] grid s-d-540:grid-cols-2 gap-y-[3%] s-d-540:h-auto s-d-540:gap-[3%] ">
        <div className="w-[100%] s-d-540:flex s-d-540:items-center s-d-540:hidden">
          <img
            src={nft1}
            alt=""
            className="w-full h-full  object-cover rounded-[10px] s-d-540:h-auto"
          />
        </div>

        <article className="w-full card s-d-540:bg-transparent s-d-540:px-[7%] s-d-540:py-[6%]">
          <h1 className="flex items-center text-title s-d-540:font-extrabold f_title-breakPoints">
            ¿Cómo funciona Imperium?
          </h1>
          <figure className=" py-[1%] w-full flex items-center justify-center">
            <img className="w-[100%] " src={lineMobile} alt="line" />
          </figure>
          <p className="textCard-description flex items-center justify-start f_text-breakPoints">
            Compra fracciones de los mejores NFTs del mundo. Los usuarios de
            Imperium pueden invertir colectivamente en NFTs de más de US$ 10.000
            con solo US$10.
          </p>
         
          
              <a target='_blank'  style={{fontSize: '1.25rem'}} className="w-full btnCard z-50 flex justify-center items-center font-extrabold s-d-540:w-[60%]  " href={`${process.env.REACT_APP_WEBSITE}`}>Ver más</a>
           
         
        </article>

        <section className="w-[100%] px-[20%] grid gap-y-[4%] s-d-540:hidden">
          <Link
            to="stepsToFollow"
            spy={true}
            smooth={true}
            offset={-35}
            duration={500}
          >
            <p className="text-colorText font-medium  w-full flex items-center justify-center f_scroll-breakPoints">
              Quiero más información
            </p>
            <figure className="w-full flex items center justify-center">
              <img src={scroll} alt="icon scroll" className="" />
            </figure>
          </Link>
        </section>

        <div className="w-full hidden s-d-540:flex s-d-540:items-center s-d-540:block">
          <img
            src={nft1}
            alt=""
            className="w-full max-h-[43rem] object-top object-cover rounded-[10px]"
          />
        </div>
      </div>
    </section>
  );
};
export default Functions;

import Slider from "react-slick";
import { carouselHomeData } from "../data/carouselHomeData";
import { SampleNextArrow, SamplePrevArrow } from "./arrows/ArrowsDesktop";
import CardDesktopHero from "./cards/hero/CardDesktop";

const GettingStartedGuideCarrouselDescktop = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 7000,
  };
  return (
    <Slider {...settings}>
      {carouselHomeData.map((dataHome, positionDataHome) => (
        <div className="w-[100vw]" key={"card" + positionDataHome}>
          <div className="h-full w-full grid grid-cols-2 px-[6%] pb-[6%] mb-[3%] d-1000:mb-[0%] d-1000:pt-[8%] s-d-540:px-[7%] s-d-540:gap-x-[3%] d-1000:items-center">
            <CardDesktopHero
              title={dataHome.title}
              description={dataHome.description}
              positionDataHome={positionDataHome}
              textButton={dataHome.textButton}
              link={dataHome.link}
            />
            <figure className="h-full w-full pr-[3%] flex items-center justity-center ">
              <img
                src={dataHome.img}
                alt={"nft" + positionDataHome}
                className=" w-full max-w-[48.9375rem] max-h-[32.9375rem] object-cover rounded-[0.625rem]"
              />
            </figure>
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default GettingStartedGuideCarrouselDescktop;
